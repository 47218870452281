import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import AppStoreButton from '../../svg/AppStore';
import LogoIcon from '../../svg/LogoIcon';
import Button from '../Button';

const Header = () => (
  <header className="sticky top-0 bg-background-lighter shadow">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center text-2xl">
        <div className="w-12 mr-3">
          <LogoIcon />
        </div>
        Library Notes
      </div>
      <div className="flex mt-4 sm:mt-0">
        <AnchorLink className="px-4" href="#why">
          Why Library Notes?
        </AnchorLink>
        <AnchorLink className="px-4" href="#features">
          Features
        </AnchorLink>
        <AnchorLink className="px-4" href="#legal">
          Legal
        </AnchorLink>
      </div>
      <div className="hidden md:block">
        <AppStoreButton />
      </div>
    </div>
  </header>
);

export default Header;
