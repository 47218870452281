import React from 'react';

const Footer = () => (
  <footer className="container flex mx-auto py-16 px-3 mt-48 text-gray-800">
        <p className="text-gray-500 px-4">Copyright © 2021  Brian Dewey</p>
        <a className="px-4" href="https://bdewey.com">Personal website</a>
        <a className="px-4" href="https://twitter.com/bdewey">@bdewey</a>
        <a className="px-4" href="https://github.com/bdewey/LibraryNotes">GitHub</a>
  </footer>
);

export default Footer;
