import React from 'react';
import appStoreSVG from './Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';

const AppStoreButton = () => (
  <a href="https://apps.apple.com/us/app/library-notes/id1481728384">
    <img src={appStoreSVG} alt="Download on the app store" />
  </a>
);

export default AppStoreButton;
